<template>
  <nav class="menu" style="z-index: 1000">
    <nuxt-link
        v-for="(link, index) in links"
        :key="index"
        :class="{
        'menu-item': true,
        current: currentLink === link.name,
        login: link.name === 'login' || link.name === 'logout',
      }"
        :to="link.path"
    >
      {{ link.name }}
    </nuxt-link>
    <CartIcon></CartIcon>
  </nav>
</template>

<script setup lang="ts">
// check authStore if user is logged in or not:
defineProps<{
  currentLink: string;
}>();


const refreshToken = useCookie('refreshToken');

const links = computed(() => {
  const baseLinks = [
    {name: "home", path: "/"},
    {name: "about", path: "/about"},
    {name: "books", path: "/books"},
    {name: "materials", path: "/materials"},
    {name: "contact", path: "/contact"},
  ];

  const loginLink = refreshToken.value
      ? {name: "logout", path: "/logout"}
      : {name: "login", path: "/login"};

  return [...baseLinks, loginLink];
});
</script>

<style scoped>

.menu {
  font-family: Palatino, serif;
  font-size: 11.5px;
  letter-spacing: 2px;
}

a.current {
  color: #45b97c; /* Different color for current page */
}

a.login {
  color: #a56c11; /* Darker color for login */
}

a {
  color: #4498b9;
  text-decoration: none;
}

.desktop a {
  margin-left: 17px;
}

@media (max-width: 600px) {
  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  .menu-item {
    width: 100%;
  }

  a {
    width: 100%;
    text-align: center;
    padding: 12px 0;
    font-size: 16px;
    display: block;
    background-color: white;
  }
}

</style>
