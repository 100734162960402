<template>
  <nuxt-link to="/cart" style="position: relative">
    <img src="/cart.png" alt="Cart Icon"/>
    <span v-if="cart.count" class="count">{{ cart.count }}</span>
  </nuxt-link>
</template>
<script setup lang="ts">
import {useCartStore} from "~/store/cart";

const cart = useCartStore();
onMounted(() => {
  cart.initCart();
});

</script>

<style scoped>
.count {
  position: absolute;
  top: 0;
  right: -20px;
  background-color: #a56c11;
  color: #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 10px;
  font-weight: bold;
}

img {
  width: 15px;
  height: 15px;
  margin-bottom: -3px;
}
</style>
