<template>
  <div class="logo-img"></div>
</template>
<style scoped>
.logo-img {
  background-image: url('/logo.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
