<template>
  <div class="mobile-content mobile">
    <div class="menu-overlay" v-if="showingMenu" @click="toggleMenu">
      <Menu
          :current-link="currentLink"
          class="menu-content"
      ></Menu>
    </div>

    <div class="menu-bar">
      <Button class="toggle-menu-button"
              @click="toggleMenu()"
      >
        <span :class="{
          'pi': true,
          'pi-times': showingMenu,
          'pi-bars': !showingMenu,
        }"></span>
      </Button>
      <div class="menu-bar-logo">
        <Logo class="menu-bar-logo-inner"></Logo>
      </div>
    </div>
    <div class="inner">
      <slot/>
    </div>
    <Footer class="footer" :current-link="currentLink"></Footer>
  </div>

  <div class="desktop-content desktop">
    <div class="heading">
      <Logo class="logo"></Logo>
      <Menu
          :current-link="currentLink"
          class="desktop-menu"
      ></Menu>
    </div>

    <div class="inner">
      <slot/>
    </div>
    <Footer class="footer" :current-link="currentLink"></Footer>
  </div>
</template>
<script setup lang="ts">

const showingMenu = ref(false);
const currentLink = ref("");

const toggleMenu = () => {
  showingMenu.value = !showingMenu.value;
};


</script>
<style scoped>

.desktop-menu {
  position: absolute;
  right: 59px;
  top: 55.25px;
}

.footer {
  flex-shrink: 0;
}

.logo {
  position: absolute;
  top: 34.75px;
  left: 135.75px;
  width: 254px;
  height: 106px;
}

.menu-bar-logo {
  margin: 0;
  padding: 0;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-bar-logo-inner {
  height: 35px;
  width: 130px;
}

.heading {
  height: 200px;
}

.inner {
  flex: 1;
}

.mobile .inner {
  position: relative;
  padding-top: 50px;

}

.desktop .menu {
  position: absolute;
  right: 59px;
  top: 55.25px
}

.desktop-content {
  width: 1000px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.toggle-menu-button {
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #4498b9;
  font-size: 20px;
}

.menu-overlay {
  position: fixed;
  top: 50px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  overflow: scroll;
  padding: 0;
  margin: 0;
}

.menu-content {
  padding: 0;
  margin: 0;
  width: 100%;
}

.menu-bar {
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 999;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.mobile-content {
  width: 100vw;
  max-width: 100vw;
  overflow: clip;
  padding: 50px 0 0;
  margin: 0;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
