<template>
  <div class="links" style="display: flex; flex-direction: column">
    <a href="/shipping-and-returns">shipping & returns</a>
    <a href="/privacy-policy">privacy policy</a>
    <a href="/terms-of-use">terms of use</a>
  </div>
</template>
<style scoped>

.links {
  font-family: Palatino, serif;
  font-size: 11px;
  letter-spacing: 2px;
  line-height: 21px;
}

.links a {
  text-decoration: none;
  color: #007179;
}
</style>
