import {defineStore} from 'pinia';
import {$fetch} from "ofetch";


const shopifyApi = 'https://shop.spiralupparenting.com.au/api/2023-07/graphql.json';
const shopifyHeaders = {
    'X-Shopify-Storefront-Access-Token': '63a167ad35bbffb9cbed67a5806a1fd7',
    'Content-Type': 'application/json'
};

export interface CartItem {
    lineId: string,
    id: string;
    quantity: number;
    name: string;
    price: string;
    totalPrice: string;
    image: string;
}

export const useCartStore = defineStore('cart', {
    state: () => ({
        count: 0 as number,
        checkoutUrl: null as string | null | undefined,
        items: {} as Record<string, CartItem>,
    }),
    actions: {
        async updateQuantity(id: string, quantity: number) {
            // await this.initCart();
            const cartIdCookie = useCookie('cartId');
            if (!cartIdCookie.value) {
                throw new Error('Could not find cart');
            }

            this.items = Object.values(this.items).reduce((acc: Record<string, CartItem>, item) => {
                if (item.lineId === id) {
                    item.quantity = quantity;
                    item.totalPrice = '';
                }
                acc[item.id] = item;
                return acc;
            }, {} as Record<string, CartItem>);


            const cartId = cartIdCookie.value;
            // console.log("Cart ID:", cartId);
            // console.log("Updating item with id:", id);

            const {data, errors} = await $fetch(shopifyApi, {
                method: 'POST',
                headers: shopifyHeaders,
                body: JSON.stringify({
                    query: `mutation {
cartLinesUpdate(cartId: "${cartId}", lines: [{ id: "${id}", quantity: ${quantity} }]) {
    cart {
        id
    }
    userErrors {
        field
        message
    }
}
}`
                })
            });
            if (errors) {
                throw new Error('Error removing from cart');
            }
            if (data?.cartLinesUpdate?.userErrors?.length) {
                throw new Error(`Error updating cart: ${JSON.stringify(data.cartLinesUpdate.userErrors[0].message)}`);
            }
            // console.log("New Cart:", JSON.stringify(data, null, 2));
            await this.updateCartItems();
        },
        async addItem(id: string) {
            await this.initCart();
            const cartIdCookie = useCookie('cartId');
            if (!cartIdCookie.value) {
                throw new Error('Could not find cart');
                //     return;
            }

            const cartId = cartIdCookie.value;
            // console.log("Cart ID:", cartId);
            // console.log("Adding item with id:", id);


            const {data, errors} = await $fetch(shopifyApi, {
                method: 'POST',
                headers: shopifyHeaders,
                body: JSON.stringify({
                    query: `mutation {
                cartLinesAdd(cartId: "${cartId}", lines: [{ merchandiseId: "${id}", quantity: 1 }]) {
                    cart {
                        id
                    }
                    userErrors {
                        field
                        message
                    }
                }
            }`
                })
            });
            if (errors) {
                throw new Error('Error adding to cart');
            }
            if (data?.cartLinesAdd?.userErrors?.length) {
                throw new Error(`Error adding to cart: ${JSON.stringify(data.cartLinesAdd.userErrors[0].message)}`);
            }
            // console.log("New Cart:", JSON.stringify(data, null, 2));

            // Navigate to the cart page:
            // console.log("New Cart:", data);
            await this.updateCartItems();

        },
        async updateCartItems() {
            const cartId = useCookie('cartId').value;
            const {data, errors} = await $fetch(shopifyApi, {
                method: 'POST',
                headers: shopifyHeaders,
                body: JSON.stringify({
                    query: `
                    {
                        cart(id: "${cartId}") {
                            checkoutUrl,
                            lines(first: 100) {
                              edges {
                                node {
                                  id,
                                  quantity,
                                  merchandise {
                                    ... on ProductVariant {
                                      id,
                                      product {
                                        title
                                      }
                                      image {
                                        transformedSrc(maxWidth: 100, maxHeight: 100)
                                      },
                                      price {
                                        amount,
                                        currencyCode,
                                      }
                                    }
                                  }
                                }
                              }
                            }
                        }
                    }`
                })
            });
            if (errors) {
                console.error("Error getting checkout URL:", JSON.stringify(errors, null, 2));
                throw new Error('Error getting checkout URL');
            }

            if (data.errors?.length) {
                throw new Error(`Error getting checkout: ${data.errors[0].message}`);
            }

            // console.log("Checkout URL:", JSON.stringify(data, null, 2));
            // const variantString = data.cart.lines.edges.map((item: any) => {
            //     return `${item.node.merchandise.id.split('/').pop()}:${item.node.quantity}`;
            // }).join(',');

            this.checkoutUrl = data.cart.checkoutUrl + '?storefront=true';

            this.items = data.cart.lines.edges.reduce((acc: Record<string, CartItem>, item: any) => {
                const variant = item.node.merchandise;
                acc[variant.id] = {
                    lineId: item.node.id,
                    id: variant.id,
                    quantity: item.node.quantity,
                    name: variant.product.title,
                    price: `\$${variant.price.amount} ${variant.price.currencyCode}`,
                    totalPrice: `\$${(variant.price.amount * item.node.quantity).toFixed(2)} ${variant.price.currencyCode}`,
                    image: variant.image.transformedSrc,
                };
                return acc;
            }, {} as Record<string, CartItem>);

            this.count = Object.values(this.items).reduce((acc, item) => {
                return acc + item.quantity;
            }, 0);

            // this.checkoutUrl = `https://shop.spiralupparenting.com.au/cart/${variantString}?storefront=true`;
        },

        async refreshCartCount() {
            const cartIdCookie = useCookie('cartId');


            const cartId = cartIdCookie.value;

            const {data, errors} = await $fetch(shopifyApi, {
                method: 'POST',
                headers: shopifyHeaders,
                body: JSON.stringify({
                    query: `
{
    cart(id: "${cartId}") {
        id,
        totalQuantity
    } 
}`,
                })
            });

            if (errors) {
                throw new Error('Error getting cart');
            }
            if (data?.errors?.length) {
                throw new Error(`Error getting cart: ${data.errors[0].message}`);

            }
            if (data) {
                this.count = data?.cart?.totalQuantity ?? 0;
            }

            await this.updateCartItems();


        },
        async initCart() {
            const cartIdCookie = useCookie('cartId');

            if (cartIdCookie.value) {
                try {
                    await this.refreshCartCount();
                    return;
                } catch (e) {
                    console.error("Error refreshing cart:", e);
                }
            }
            const {data, errors} = await $fetch(shopifyApi, {
                method: 'POST',
                headers: shopifyHeaders,
                body: JSON.stringify({
                    query: `mutation {
                    cartCreate {
                        cart {
                            id
                        }
                        userErrors {
                            field
                            message
                        }
                    }
                }`
                })
            });
            // check for userErrors:
            if (data?.cartCreate?.userErrors?.length) {
                throw new Error(`Error creating cart: ${data.cartCreate.userErrors[0].message}`);
            }
            if (errors) {
                throw new Error(`Error creating cart: ${errors}`);
            }

            const cartId = data.cartCreate.cart.id;
            cartIdCookie.value = cartId;
            await this.updateCartItems();
        },
    },
});
