<template>
  <div style="display: flex; justify-content: center">
    <a href="https://www.facebook.com/profile.php?id=61556914384510"><img src="/logo-facebook.jpg"
                                                                          class="link-logo"/></a>
    <a href="https://www.instagram.com/spiralup_parenting/"><img src="/logo-instagram.png" class="link-logo"/></a>
    <a href="https://www.pinterest.com.au/SpiralUpParenting/"><img src="/logo-pinterest.png" class="link-logo"/></a>
  </div>
</template>
<style scoped>
.ack p {
  font-size: 8.8px;
  font-style: italic;
  line-height: 13px;
  color: #4d555b;
}

.link-logo {
  height: 30px;
  margin: 10px;
}

.links a {
  text-decoration: none;
  color: #007179;
}
</style>
